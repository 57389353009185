import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';

@Injectable()
export class DataResolver implements Resolve<any> {
  public resolve() {
    return of({
      res:
        /* eslint-disable */
        `Angular 2 Seed Advanced is a starter project that implements best practices in  coding, building and testing Angular 2 apps.
Additionally it adds various enhancements and integrations for large global projects.`
      /* eslint-enable */
    });
  }
}
// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [DataResolver];
