import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccordionListModule, DirectivesModule, TemplatePickerModule } from '@apiax/web-commons';
import { TranslateModule } from '@ngx-translate/core';
import { VerificationsPanelComponent } from './verifications-panel.component';


@NgModule({
  declarations: [VerificationsPanelComponent],
  exports: [VerificationsPanelComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    AccordionListModule,
    TranslateModule,
    DirectivesModule,
    TemplatePickerModule
  ]
})
export class VerificationsPanelModule {
}
