// import { Configuration } from '../../../generated';

export function buildConfig() {
  // const config = new Configuration();
  // config.basePath = getConfig('apiUrl');
  // return config;
}

export function getConfig(parameter: string): any {
  if (window['__config']) {
    return window['__config'][parameter];
  }
  return undefined;
}
