import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChatbotConfiguration,
  ChatbotResponse,
  ChatSession
} from '../../../models/chatbot-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorTranslationService {
  constructor(private translateService: TranslateService) {}

  public translateError(error: HttpErrorResponse): Observable<ChatbotResponse> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR_MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            chatId: null,
            type: 'error',
            messageId: '',
            message: translatedMessage,
            context: []
          };
        })
      );
  }

  public translateConversationEditError(error: HttpErrorResponse): Observable<ChatSession> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR_MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            id: '',
            startedAt: new Date(),
            userId: '',
            userEmail: '',
            language: '',
            topic: '',
            title: translatedMessage,
            context: '',
            deleted: false,
            chatHistory: [],
            chatbotVersion: '',
            collectionId: ''
          };
        })
      );
  }

  public translateConversationDeleteError(
    error: HttpErrorResponse
  ): Observable<{
    deleted: boolean;
    error: string;
  }> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR_MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            deleted: false,
            error: translatedMessage
          };
        })
      );
  }

  public translateConfigurationError(error: HttpErrorResponse): Observable<ChatbotConfiguration> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR_MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            id: '',
            error: translatedMessage,
            languages: [
              {
                iso6391: 'en',
                ietfLanguageTag: 'en-US',
                languageName: 'English'
              }
            ],
            assistants: [
              {
                id: 'error',
                name: 'Could not load assistants.'
              }
            ],
            llmProvider: {
              id: 'error',
              name: 'Could not load LLM providers.'
            },
            collections: []
          };
        })
      );
  }
}
