import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'shared/core';

@Injectable()
export class LoadingGuard implements CanActivate {
  constructor(private appService: AppService) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.appService.loadData();
  }
}
