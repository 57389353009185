import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { VerificationPreview } from 'app/models/chat-preview.model';

import { VerificationRequestsApiService } from '../../../../generated';
import { ChatSessionWithVerifications } from '../../../models/chat-session-with-verifications.model';
import { StoreValueStatus } from '../../../models/store-value-status.model';
import { UserInfo } from '../../../models/user-info.model';
import { VerificationsUseCases } from './usecases/verifications-use-cases';
import {
  CreateVerificationRequest,
  GetChatSessionWithVerificationRequests,
  LoadAssignableUsers,
  SelectedChatMessage,
  UpdateChatSessionWithVerificationRequestsActivity,
  UpdateChatVerification,
  UpdateVerificationRequestAssignee,
  UpdateVerificationRequestStatus,
  VerifyAnswer
} from './verifications.action';

export interface VerificationsStateModel {
  chatSessionWithVerificationRequests: ChatSessionWithVerifications;
  chatSessionRequestsFieldLoadStatus: StoreValueStatus;
  selectedChatMessage: string;
  assignableUsers: UserInfo[];
  assignableUsersLoadStatus: StoreValueStatus;
  lastCreatedVerificationRequest: VerificationPreview;
}

export const DEFAULT_STATE: VerificationsStateModel = {
  chatSessionWithVerificationRequests: undefined,
  chatSessionRequestsFieldLoadStatus: StoreValueStatus.NOT_LOADED,
  selectedChatMessage: undefined,
  assignableUsers: [],
  assignableUsersLoadStatus: StoreValueStatus.NOT_LOADED,
  lastCreatedVerificationRequest: undefined
};

@State<VerificationsStateModel>({
  name: 'verifications',
  defaults: DEFAULT_STATE
})
@Injectable()
@UntilDestroy()
export class VerificationsState {
  private verificationsUseCases: VerificationsUseCases;

  constructor(verificationRequestsApiService: VerificationRequestsApiService) {
    this.verificationsUseCases = new VerificationsUseCases(verificationRequestsApiService);
  }

  @Selector()
  public static chatSessionWithVerificationRequests(
    state: VerificationsStateModel
  ): ChatSessionWithVerifications {
    return state.chatSessionWithVerificationRequests;
  }

  @Selector()
  public static chatSessionRequestsFieldLoadStatus(
    state: VerificationsStateModel
  ): StoreValueStatus {
    return state.chatSessionRequestsFieldLoadStatus;
  }

  @Selector()
  static selectedChatMessage(state: VerificationsStateModel): string {
    return state.selectedChatMessage;
  }

  @Selector()
  public static assignableUsers(state: VerificationsStateModel): UserInfo[] {
    return state.assignableUsers;
  }

  @Selector()
  public static assignableUsersLoadStatus(state: VerificationsStateModel): StoreValueStatus {
    return state.assignableUsersLoadStatus;
  }

  @Selector()
  public static lastCreatedVerificationRequest(
    state: VerificationsStateModel
  ): VerificationPreview {
    return state.lastCreatedVerificationRequest;
  }

  @Action(SelectedChatMessage)
  public setSelectedChatMessage(
    context: StateContext<VerificationsStateModel>,
    action: SelectedChatMessage
  ): void {
    this.verificationsUseCases.updateSelectedChatMessage(context, action);
  }

  @Action(CreateVerificationRequest)
  public createVerificationRequest(
    context: StateContext<VerificationsStateModel>,
    action: CreateVerificationRequest
  ) {
    return this.verificationsUseCases.createVerificationRequest(context, action);
  }

  @Action(VerifyAnswer)
  public verifyAnswer(context: StateContext<VerificationsStateModel>, action: VerifyAnswer) {
    return this.verificationsUseCases.verifyVerificationRequest(context, action);
  }

  @Action(GetChatSessionWithVerificationRequests)
  public getChatSessionWithVerificationRequests(
    context: StateContext<VerificationsStateModel>,
    action: GetChatSessionWithVerificationRequests
  ) {
    return this.verificationsUseCases.getChatSessionWithVerificationRequests(context, action);
  }

  @Action(LoadAssignableUsers)
  public loadAssignableUsers(
    context: StateContext<VerificationsStateModel>,
    action: LoadAssignableUsers
  ) {
    return this.verificationsUseCases.loadAssignableUsers(context, action);
  }

  @Action(UpdateVerificationRequestStatus, { cancelUncompleted: true })
  public updateVerificationRequestStatus(
    context: StateContext<VerificationsStateModel>,
    action: UpdateVerificationRequestStatus
  ) {
    return this.verificationsUseCases.updateVerificationRequestStatus(context, action);
  }

  @Action(UpdateVerificationRequestAssignee, { cancelUncompleted: true })
  public updateVerificationRequestAssignee(
    context: StateContext<VerificationsStateModel>,
    action: UpdateVerificationRequestAssignee
  ) {
    return this.verificationsUseCases.updateVerificationRequestAssignee(context, action);
  }

  @Action(UpdateChatVerification)
  public updateChatVerification(
    context: StateContext<VerificationsStateModel>,
    action: UpdateChatVerification
  ) {
    return this.verificationsUseCases.updateChatVerification(context, action);
  }

  @Action(UpdateChatSessionWithVerificationRequestsActivity)
  public updateChatPreviewActivity(
    context: StateContext<VerificationsStateModel>,
    action: UpdateChatSessionWithVerificationRequestsActivity
  ) {
    return this.verificationsUseCases.updateChatSessionWithVerificationRequestsActivity(context, action);
  }

}
