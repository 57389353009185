import { Injectable } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateErrorMessage } from './chat-errors.action';
import { ErrorsUseCases } from './usecases/chat-errors-use-cases';

export interface ErrorStateModel {
  errorMessage: string;
}

export const DEFAULT_STATE: ErrorStateModel = {
  errorMessage: undefined
};

@State<ErrorStateModel>({
  name: 'errors',
  defaults: DEFAULT_STATE
})
@Injectable()
@UntilDestroy()
export class ErrorsState {
  private errorsUseCases: ErrorsUseCases;

  constructor() {
    this.errorsUseCases = new ErrorsUseCases();
  }


  @Selector()
  public static chatSessionError(state: ErrorStateModel): string {
    return state.errorMessage;
  }

  @Action(UpdateErrorMessage)
  public UpdateChatVerificationBal(
    context: StateContext<ErrorStateModel>,
    action: UpdateErrorMessage
  ) {
    return this.errorsUseCases.updateErrorMessage(context, action);
  }
}
