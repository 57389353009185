// angular
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotificationService } from '@apiax/web-commons';
import { NgxsModule } from '@ngxs/store';

import {
  AppService,
  AuthorizationService,
  RouterExtensions,
  StorageManagerService
} from './services';

const CORE_PROVIDERS: any[] = [
  NotificationService,
  AppService,
  AuthorizationService,
  RouterExtensions,
  StorageManagerService
];

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    NgxsModule.forFeature([]) //States here
  ],
  declarations: [],
  exports: [CommonModule, FormsModule, RouterModule, HttpClientModule],
  providers: [CORE_PROVIDERS]
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and ConsoleService per platform
  public static forRoot(configuredProviders: any[]): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: configuredProviders
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule already loaded; Import in root module only.');
    }
  }
}
