import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  AccordionListComponent,
  AccordionListData,
  DateUtils,
  TemplatePickerOption
} from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { ChangeStatusRequest } from '../../../generated';

import { PhotoDAO } from '../../domain/daos/photo.dao';
import { TemplatePickerOptionMapper } from '../../domain/mappers/template-picker-option-mapper';
import {
  LoadAssignableUsers,
  SelectedChatMessage,
  UpdateVerificationRequestAssignee,
  UpdateVerificationRequestStatus
} from '../../domain/stores/verifications/verifications.action';
import { VerificationsState } from '../../domain/stores/verifications/verifications.state';
import { StoreValueStatus } from '../../models/store-value-status.model';
import { UserInfo } from '../../models/user-info.model';
import { VerificationStatus } from '../../models/verification-status.model';
import { VerificationRequest } from '../../models/verification-request.model';

@UntilDestroy()
@Component({
  selector: 'app-verifications-panel',
  templateUrl: './verifications-panel.component.html',
  styleUrls: ['./verifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationsPanelComponent implements OnInit, AfterViewInit {
  public readonly UNASSIGNED_ID = 'unassigned';
  @ViewChild('accordion') accordion: AccordionListComponent;
  readonly DateUtils = DateUtils;
  readonly PhotoDAO = PhotoDAO;
  readonly VerificationStatus = VerificationStatus;

  public isLoading$: Observable<boolean>;
  public verifications$: Observable<VerificationRequest[]>;
  public verificationsData$ = new BehaviorSubject<AccordionListData<any>[]>([]);
  public chatMessageId: string;
  public openedChatMessageId: string;

  private verifications: VerificationRequest[];

  public assignees$: Observable<TemplatePickerOption[]>;
  public assignees: UserInfo[];
  public form: FormControl = new FormControl();
  public inlineEditingType: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.isLoading$ = store.select(VerificationsState.chatSessionRequestsFieldLoadStatus).pipe(
      untilDestroyed(this),
      map(s => s === StoreValueStatus.NOT_LOADED || s === StoreValueStatus.LOADING)
    );

    this.verifications$ = store
      .select(VerificationsState.chatSessionWithVerificationRequests)
      .pipe(map(c => c?.verificationRequests || []));
  }

  ngAfterViewInit(): void {
    const number = this.verificationsData$.value.findIndex(o => o.isOpened);
    if (this.accordion && number >= 0) {
      this.accordion.scrollToIndex(number);
    }
  }

  public onOpenCloseVerification(verificationOpenClose: any) {
    if (verificationOpenClose.isOpened) {
      const verification = this.verifications.find(v => v.requestId === verificationOpenClose.id);
      if (verification.chatMessageId !== this.openedChatMessageId) {
        this.store.dispatch(new SelectedChatMessage({ chatMessageId: verification.chatMessageId }));
      }
      this.inlineEditingType = null;
    }

    this.setChatMessageIdWithIsOpen();
  }

  private setChatMessageIdWithIsOpen() {
    const requestId = this.verificationsData$.value.find(v => v.isOpened)?.id;
    if (requestId) {
      const verification = this.verifications.find(v => v.requestId === requestId);
      this.openedChatMessageId = verification?.chatMessageId;
    } else {
      this.openedChatMessageId = undefined;
    }
  }

  ngOnInit() {
    this.translateService.use('en');
    this.route.firstChild.firstChild.params.pipe(first()).subscribe(params => {
      this.chatMessageId = params['chatMessageId'];
      this.openedChatMessageId = this.chatMessageId;
      this.store.dispatch(new SelectedChatMessage({ chatMessageId: this.chatMessageId }));

      if (
        this.store.selectSnapshot(VerificationsState.assignableUsersLoadStatus) !==
        StoreValueStatus.LOADED
      ) {
        this.store.dispatch(new LoadAssignableUsers());
      }
      this.initSubscriptions();
    });
  }

  private initSubscriptions() {
    this.verifications$
      .pipe(
        distinctUntilChanged(),
        filter(verifications => !isNil(verifications)),
        untilDestroyed(this)
      )
      .subscribe(verifications => {
        this.verifications = verifications;
        this.updateVerifications(verifications);
        this.cdr.markForCheck();
      });

    this.store
      .select(VerificationsState.assignableUsers)
      .pipe(untilDestroyed(this))
      .subscribe(results => {
        this.assignees = results;
        this.assignees$ = of(
          this.assignees.map(obj => TemplatePickerOptionMapper.mapToTemplatePickerOption(obj))
        );
      });
  }

  private updateVerifications(verifications: VerificationRequest[]) {
    const verificationsData = this.retrieveVerificationsData(verifications, false);
    this.verificationsData$.next(verificationsData);
    this.cdr.markForCheck();
  }

  private retrieveVerificationsData(
    verifications: VerificationRequest[],
    isEditable: boolean
  ): AccordionListData<VerificationRequest>[] {
    return verifications.map(verification => {
      return {
        id: verification.requestId,
        isOpened: verification.chatMessageId == this.openedChatMessageId,
        editable: isEditable,
        disabled: false,
        model: verification
      };
    });
  }

  public changeVerificationRequestStatus(verificationRequest: VerificationRequest) {
    this.store
      .dispatch(
        new UpdateVerificationRequestStatus({
          requestId: verificationRequest.requestId,
          newStatus:
            verificationRequest.status === VerificationStatus.REQUESTED
              ? ChangeStatusRequest.NewStatusEnum.IN_PROGRESS
              : ChangeStatusRequest.NewStatusEnum.REQUESTED
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.inlineEditingType = null;
        this.cdr.markForCheck();
      });
  }

  public changeVerificationRequestAssignee(
    selectedPickerOption: TemplatePickerOption,
    verificationRequestId: string
  ) {
    this.form.setValue(undefined);
    this.store
      .dispatch(
        new UpdateVerificationRequestAssignee({
          requestId: verificationRequestId,
          assigneeId: selectedPickerOption.id,
          assigneeName: selectedPickerOption.label,
          assigneePhotoId: this.assignees.find(obj => obj.userId === selectedPickerOption.id)
            ?.photoId
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.inlineEditingType = null;
        this.cdr.markForCheck();
      });
  }

  public setItemType($event: MouseEvent, editable: boolean, value: string) {
    $event.stopPropagation();
    if (editable) {
      this.inlineEditingType = value;
    }
  }

  public onClickedOutside(outsideStatus: boolean, type: string) {
    if (this.inlineEditingType === type && outsideStatus) {
      this.inlineEditingType = null;
    }
  }
}
